import React, { useState, useEffect } from "react"

import { Button } from "components/anti/buttons/buttons"
import Layout from "components/layout"
import PinInput from "react-pin-input"
import { useMutation } from "@apollo/client"
import { parseCookies, setCookie, destroyCookie } from "nookies"
import { navigate } from "gatsby"

import Seo from "components/seo"
import { loginMemberQuery } from "components/utils/streamworx"
import circle from "assets/img/roll-animate.svg"

const LoginPin = () => {
  const cookies = parseCookies()

  const [pin, setPin] = useState()
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [errMsg, setErrMsg] = useState("")

  const [loginMember] = useMutation(loginMemberQuery, {
    context: { clientName: "streamworx" },
  })

  const onChange = value => {
    setPin(value)
    if (value.length >= 6) {
      setDisabledBtn(false)
    } else {
      setDisabledBtn(true)
    }
  }

  useEffect(() => {
    var list = document.querySelectorAll("[placeholder]")
    for (let i = 0; i < list.length; i++) {
      if (list[i].placeholder) {
        list[i].classList.add("filled")
      } else {
        list[i].classList.remove("filled")
      }
    }
  }, [pin])

  const handleSubmit = () => {
    setLoadingBtn(true)
    setErrMsg("")
    const phoneNumber = cookies.phoneNumber
    const loginDirect = cookies.loginDirect
    // const covid19TestForm = cookies.directCovid19Test

    const value = {
      phoneNumber,
      pin,
    }

    loginMember({
      variables: { ...value },
    })
      .then(res => {
        const { status, token, message } = res.data.loginMember
        if (status === "SUCCESS") {
          console.log("Sukses")
          setCookie(null, "authToken", token, {
            maxAge: 12 * 60 * 60,
            path: "/",
          })
          destroyCookie({}, "phoneNumber")
          if (loginDirect) {
            navigate(loginDirect)
            destroyCookie({}, "loginDirect")
          } else {
            navigate("/")
          }
          // if (covid19TestForm) {
          //     console.log("success terkirim form covid-19-test")
          //     navigate("/covid-19-test#success")
          // } else {
          //     navigate("/")
          // }
        } else {
          setLoadingBtn(false)
          setErrMsg(message)
        }
      })
      .catch(err => console.log("err", err))
  }

  useEffect(() => {
    const phoneNumber = cookies.phoneNumber
    if (!phoneNumber) {
      // navigate("/login")
      navigate("/")
    }
  }, [])

  return (
    <Layout>
      <Seo title="Verify" />
      <section className="py-main sc-login-pin">
        <div className="container mw-sm">
          <Button
            variant="link"
            className="btn-back-help ai-arrow-from-left mb-3"
            link={"/login"}
          >
            Kembali
          </Button>
          <div className="pin-field">
            <h2>Masukkan PIN</h2>
            <p>Mohon masukkan 6 digit kode PIN untuk verifikasi akun kamu</p>
            <PinInput
              length={6}
              focus
              // disabled
              secret
              type="numeric"
              onChange={onChange}
            />
            <Button
              variant="primary"
              type="reset"
              disabled={disabledBtn || loadingBtn}
              onClick={() => handleSubmit()}
              className="btn-block"
            >
              {loadingBtn ? (
                <img src={circle} className="img-fluid loading" />
              ) : (
                "Lanjut"
              )}
            </Button>
            <div className="invalid-feedback">{errMsg}</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LoginPin
